import React from "react"
import Image from "gatsby-image"
import StyledOffer from "./StyledOffer"

const OfferImage = ({ description, fluid }) => {
  return (
    <StyledOffer>
      <div>
        <Image
          className="loading"
          fluid={fluid}
          alt={description}
          title={description}
        />
      </div>
      <h3>{description}</h3>
    </StyledOffer>
  )
}

export default OfferImage
