import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import Heading from "../components/simpleComponents/Heading"

const StyledAssessment = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
  padding: 0 var(--margin-x);

  @media (min-width: 850px) {
    margin: 0 auto;
  }

  .contactButton {
    display: block;
    flex-basis: 140px;
    margin: 0 10px 10px;
    padding: 10px;
    text-align: center;
    border: 2px solid var(--primary-color);
    color: var(--secondary-color);
    background-color: var(--primary-color);
    font-weight: 700;
    text-decoration: none;
  }

  @media (min-width: 360px) {
    .contactButton {
      margin: 0 10px;
    }
  }

  @media (min-width: 1020px) {
    .contactButton {
      border: 2px solid var(--primary-color);
      color: var(--primary-color);
      background-color: var(--secondary-color);
      transition: background-color 0.3s, border 0.3s, color 0.3s;
    }

    .contactButton:hover {
      border: 2px solid var(--primary-color);
      color: var(--secondary-color);
      background-color: var(--primary-color);
    }
  }

  p {
    flex-basis: 100%;
    margin-bottom: var(--margin-y);
    font-size: var(--font-size);
    text-align: center;
  }
`

const Assessment = () => {
  return (
    <StyledAssessment>
      <Heading shadow>Wycena</Heading>
      <p>
        Każde zlecenie jest wyceniane indywidualnie, cena zależy od rodzaju i
        rozmiaru przewiertów oraz użytych do tego celu narzędzi.
      </p>
      <p>Jeżeli masz jakieś pytania, skontaktuj się z nami:</p>
      <motion.a
        whileTap={{ scale: 0.9 }}
        whileHover={{
          translateY: -3,
          boxShadow: "0 10px 8px -9px var(--primary-color)",
        }}
        className="contactButton"
        href="tel:512-746-858"
        title="Telefon kontaktowy"
      >
        Zadzwoń
      </motion.a>
      <motion.a
        whileTap={{ scale: 0.9 }}
        whileHover={{
          translateY: -3,
          boxShadow: "0 10px 8px -9px var(--primary-color)",
        }}
        className="contactButton"
        href="mailto:cieciewiercenie@interia.pl"
        title="Adres e-mail"
      >
        Napisz maila
      </motion.a>
    </StyledAssessment>
  )
}

export default Assessment
