import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const StyledConstructions = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--margin-y) * 1.8);
  padding: 15px 0;
  background-color: var(--tertiary-color);
  box-shadow: 0 0 10px -2px #888;

  .titleConstructions {
    flex-basis: 100%;
    font-size: var(--font-size);
    text-align: center;
    margin-bottom: 15px;
  }

  .iconWrapper {
    margin: 0 auto 15px;
    padding: 15px;
    width: 75px;
    height: 75px;
    background-color: none;
    box-shadow: 0 0 2px 2px var(--primary-color);
    border-radius: 50%;
    color: var(--primary-color);
  }

  .constructionsList {
    flex-basis: 100%;
  }
`

const Construction = styled.li`
  font-size: var(--font-size);
  padding: 5px;
  text-align: center;

  &:nth-child(odd) {
    background-color: var(--transparent-color);
  }
`

const Constructions = () => (
  <StyledConstructions>
    <div className="iconWrapper">
      <FontAwesomeIcon icon="building" />
    </div>
    <h2 className="titleConstructions">
      Dzięki naszym usługom pomogliśmy zrealizować takie inwestycje jak:
    </h2>
    <ul className="constructionsList">
      <Construction>Gdański Teatr Szekspirowski</Construction>
      <Construction>Centrum Handlowe Ogrody w Elblągu</Construction>
      <Construction>Browar Żywiec w Elblągu</Construction>
      <Construction>Alchemia Gdańsk przy ul. Grunwaldzkiej</Construction>
      <Construction>Hotel PURO w Gdańsku</Construction>
      <Construction>
        Obiekty hotelowe na Granarii przy Gdańskiej Starówce
      </Construction>
      <Construction>Elektrownia Kozienice</Construction>
    </ul>
  </StyledConstructions>
)

export default Constructions
