import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const StyledFeature = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 150px;
  align-items: space-between;
  margin: 10px 0;
  padding: 10px 0;
  background-color: var(--tertiary-color);
  transform: scale(1);
  box-shadow: 0 0 10px -2px #888;

  @media (min-width: 400px) {
    margin: 10px;
  }

  @media (min-width: 1020px) {
    transition: 0.3s;
    &:hover {
      transform: scale(1.05);
      background-color: var(--transparent-color);
    }
  }

  .iconWrapper {
    margin: 0 auto;
    padding: 15px;
    width: 75px;
    height: 75px;
    background-color: none;
    box-shadow: 0 0 2px 2px var(--primary-color);
    border-radius: 50%;
    color: var(--primary-color);
  }

  .paragraph {
    padding: 5px;
    font-size: 14px;
    text-align: center;
  }
`

const Feature = ({ icon, children }) => (
  <StyledFeature>
    <div className="iconWrapper">
      <FontAwesomeIcon icon={icon} />
    </div>
    <p className="paragraph">{children}</p>
  </StyledFeature>
)

export default Feature
