import React from "react"
import styled from "styled-components"

const StyledCollaborators = styled.ul`
  position: relative;
  width: 100%;
  height: 60px;
  overflow: hidden;
`

const Collaborator = styled.li`
  position: absolute;
  top: calc(50% - 12px);
  left: 0;
  padding: 0 10px;
  font-size: 20px;
  font-weight: 900;

  @media (min-width: 400px) {
    padding: 0 15px;
    font-size: 22px;
  }
`

class Collaborators extends React.Component {
  state = {
    init: false,
    start: false,
    items: [],
    slider: null,
  }

  initSlider() {
    const [...items] = this.state.items

    const widthSlider = items.reduce((x, y) => {
      if (typeof x === "number") return x + y.offsetWidth
      return x.offsetWidth + y.offsetWidth
    })

    this.state.slider.style.width = `${widthSlider}px`

    let currentItemsWidth = 0

    for (let i = 0; i < items.length; i++) {
      items[i].style.transform = `translateX(${currentItemsWidth}px)`
      items[i] = {
        item: items[i],
        w: items[i].offsetWidth,
        x: currentItemsWidth,
      }
      currentItemsWidth += items[i].item.offsetWidth
    }
    this.setState({ items, init: true })
  }

  startSlider(elements) {
    const items = elements
    let beforeTime = performance.now()
    const animateSlider = currentTime => {
      if (currentTime - beforeTime > 16) {
        beforeTime = currentTime
        for (let i = 0; i < items.length; i++) {
          let { item, x, w } = items[i]
          x = x - 3

          if (x <= w * -1) {
            let sliderWidth = this.state.slider.style.getPropertyValue("width")
            sliderWidth = sliderWidth.substring(0, sliderWidth.length - 2)
            x = Number(sliderWidth) - w
          }

          item.style.transform = `translateX(${x}px)`
          items[i] = { item, x, w }
        }
      }
      requestAnimationFrame(animateSlider)
    }
    animateSlider()
    this.setState({ items, init: true, start: true })
  }

  componentDidUpdate() {
    if (!this.state.init) {
      this.initSlider()
    }
    if (this.state.init && !this.state.start) {
      this.startSlider(this.state.items)
    }
  }

  componentDidMount() {
    const items = [...document.querySelectorAll(".collaborator")]
    const slider = document.querySelector(".collaboratorsSlider")
    this.setState({ items, slider })
  }

  render() {
    return (
      <StyledCollaborators className="collaboratorsSlider">
        {this.props.items.map((item, index) => (
          <Collaborator className="collaborator" key={index} index={index}>
            {item}
          </Collaborator>
        ))}
      </StyledCollaborators>
    )
  }
}

export default Collaborators
