import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const Nav = styled.nav`
  color: var(--font-color);
  ul {
    text-align: center;
  }

  li {
    display: block;
    margin-bottom: 30px;
  }

  @media (min-width: 400px) {
    li {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 500px) {
    li {
      margin-bottom: 50px;
    }
  }

  @media (orientation: landscape) {
    li {
      margin-bottom: 20px;
    }
  }
`

const NavBtn = styled(motion.a)`
  display: inline-block;
  width: 150px;
  padding: 10px;
  background-color: ${({ primary }) =>
    primary ? "var(--primary-color)" : "none"};
  border-radius: 30px;
  color: var(--font-color);
  text-decoration: none;
  font-size: var(--font-size);
  transition: background-color 0.3s;

  @media (min-width: 500px) and (max-aspect-ratio: 6/8) {
    width: 200px;
    padding: 15px;
    font-size: 20px;
  }

  @media (min-aspect-ratio: 7/5) {
    width: 150px;
    padding: 10px;
    font-size: 16px;
  }

  @media (min-width: 1000px) {
    width: 175px;
    padding: 12.5px;
    font-size: 20px;

    &:hover {
      background-color: ${({ primary }) =>
        primary ? `#231c90` : `rgba(46, 37, 177, 0.3)`};
    }
  }
`

const Menu = () => (
  <Nav>
    <ul>
      <li>
        <NavBtn
          initial={{ opacity: 0, x: -200 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 1.5 }}
          primary
          href="#offer"
          title="Oferta"
        >
          Oferta
        </NavBtn>
      </li>
      <li>
        <NavBtn
          initial={{ opacity: 0, x: -200 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 1.9 }}
          href="#realisation"
          title="Realizacje"
        >
          Realizacje
        </NavBtn>
      </li>
      <li>
        <NavBtn
          initial={{ opacity: 0, x: -200 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 2.3 }}
          href="#contact"
          title="Kontakt"
        >
          Kontakt
        </NavBtn>
      </li>
    </ul>
  </Nav>
)

export default Menu
