import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ProgresBar from "./ProgresBar"
import Slides from "./Slides"
import SliderButton from "./SliderButton"

const StyledSlider = styled.div`
  position: relative;
`

const Slider = ({ data }) => {
  const filteredImages = data.filter(({ node }) =>
    node.fluid.originalName.includes("zdj")
  )

  const indexOfLastSlide = filteredImages.length - 1
  const [activeSlides, setActiveSlides] = useState([0])

  useEffect(() => {
    const oneSlide = window.matchMedia("(orientation:portrait)")
    const threeSlides = window.matchMedia("(orientation:landscape)")

    const setOneSlide = e => {
      if (e.matches) {
        setActiveSlides([activeSlides[0]])
      }
    }

    const setThreeSlides = e => {
      if (e.matches) {
        if (activeSlides[0] >= indexOfLastSlide - 2) {
          setActiveSlides([
            indexOfLastSlide - 2,
            indexOfLastSlide - 1,
            indexOfLastSlide,
          ])
        } else {
          setActiveSlides([
            activeSlides[0],
            activeSlides[0] + 1,
            activeSlides[0] + 2,
          ])
        }
      }
    }

    if (oneSlide.addEventListener) {
      oneSlide.addEventListener("change", setOneSlide)
      threeSlides.addEventListener("change", setThreeSlides)
    } else if (oneSlide.attachEvent) {
      oneSlide.attachEvent("onchange", setOneSlide)
      threeSlides.attachEvent("onchange", setThreeSlides)
    }

    setOneSlide(oneSlide)
    setThreeSlides(threeSlides)

    return () => {
      if (oneSlide.addEventListener) {
        oneSlide.removeEventListener("change", setOneSlide)
        threeSlides.removeEventListener("change", setThreeSlides)
      } else if (oneSlide.attachEvent) {
        oneSlide.detachEvent("onchange", setOneSlide)
        threeSlides.detachEvent("onchange", setThreeSlides)
      }
    }
  }, [])

  const handleClickBar = e => {
    const barWidthForOneIndex = e.target.offsetWidth / filteredImages.length
    const clickPosition = e.clientX - e.target.offsetLeft
    const selectedIndex = Math.floor(clickPosition / barWidthForOneIndex)

    // TODO: Warunek dla dwóch aktywnych slidów, jeśli okaże się nie potrzebny, usunąć.

    if (activeSlides.length === 1) {
      setActiveSlides([selectedIndex])
    } else if (activeSlides.length === 2) {
      if (selectedIndex === indexOfLastSlide) {
        setActiveSlides([indexOfLastSlide - 1, indexOfLastSlide])
      } else {
        setActiveSlides([selectedIndex, selectedIndex + 1])
      }
    } else if (activeSlides.length === 3) {
      if (selectedIndex >= indexOfLastSlide - 1) {
        setActiveSlides([
          indexOfLastSlide - 2,
          indexOfLastSlide - 1,
          indexOfLastSlide,
        ])
      } else {
        setActiveSlides([selectedIndex, selectedIndex + 1, selectedIndex + 2])
      }
    }
  }

  const changeSlide = (direction, interval = false) => {
    if (direction === "prev") {
      if (activeSlides[0] > 0) {
        setActiveSlides(activeSlides.map(slideIndex => slideIndex - 1))
      }
    } else if (direction === "next") {
      if (activeSlides[activeSlides.length - 1] < indexOfLastSlide) {
        setActiveSlides(activeSlides.map(slideIndex => slideIndex + 1))
      }
    }
  }

  return (
    <StyledSlider>
      <Slides
        data={filteredImages}
        currentSlidesId={activeSlides}
        numberOfSlides={filteredImages.length}
        changeSlide={changeSlide}
      />
      <SliderButton
        click={() => {
          changeSlide("prev")
        }}
        active={!(activeSlides[0] === 0)}
        prev
      />
      <SliderButton
        click={() => {
          changeSlide("next")
        }}
        active={!(activeSlides[activeSlides.length - 1] === indexOfLastSlide)}
      />
      <ProgresBar
        onClick={e => {
          handleClickBar(e)
        }}
        whileTap={{ scale: 1.05 }}
        currentSlidesId={activeSlides}
        numberOfSlides={filteredImages.length}
      />
    </StyledSlider>
  )
}

export default Slider
