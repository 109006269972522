import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Slider from "../components/Slider/Slider"
import Heading from "../components/simpleComponents/Heading"

const Section = styled.section`
  margin: 0 0 var(--margin-y);
  overflow: hidden;
`

const Realisation = () => {
  const images = useStaticQuery(graphql`
    {
      allImageSharp {
        edges {
          node {
            fluid {
              originalName
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `)

  return (
    <Section id="realisation">
      <Heading shadow>Realizacje</Heading>
      <Slider data={images.allImageSharp.edges} />
    </Section>
  )
}

export default Realisation
