import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Collaborators from "../components/Collaborators"

const StyledCollaborate = styled.div`
  display: flex;
  padding-top: 10px;
  flex-wrap: wrap;
  background-color: var(--tertiary-color);
  box-shadow: 0 0 10px -2px #888;

  .head {
    flex-basis: 100%;

    .iconContainer {
      margin: 0 auto;
      padding: 15px;
      width: 75px;
      height: 75px;
      background-color: none;
      box-shadow: 0 0 2px 2px var(--primary-color);
      border-radius: 50%;
      color: var(--primary-color);
    }

    .label {
      width: 300px;
      margin: 15px auto 0;
      font-size: var(--font-size);
      text-align: center;
    }
  }
`

const Collaborate = () => {
  return (
    <StyledCollaborate>
      <div className="head">
        <div className="iconContainer">
          <FontAwesomeIcon icon="handshake" />
        </div>
        <p className="label">
          Współpracujemy między innymi z takimi firmami jak:
        </p>
      </div>
      <Collaborators
        items={[
          "PORR. S.A.",
          "ALLCON SP. Z O. O.",
          "NDI S.A.",
          "KELLER POLSKA SP. Z O. O.",
          "UNIBEP S.A.",
          "EIFAGE POLSKA",
          "ATAL S.A.",
          "TORUS SP. Z O. O.",
          "ROGYB S.A.",
          "ENVITOTECH SP. Z O. O.",
        ]}
      />
    </StyledCollaborate>
  )
}

export default Collaborate
