import React from "react"
import IndexPage from "./src/pages/index"
import GlobalStyles from "./src/styles/GlobalStyles"

export const wrapPageElement = ({ element, props }) => {
  return (
    <IndexPage {...props}>
      <GlobalStyles />
    </IndexPage>
  )
}
