import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { motion } from "framer-motion"

const LogoContainer = styled(motion.div)`
  padding: 18vh 12vw 10vh;

  @media (min-width: 600px) {
    padding: 18vh 15vw 10vh;
  }

  @media (min-width: 700px) {
    padding: 16vh 20vw 10vh;
  }

  @media (min-width: 700px) {
    padding: 16vh 24vw 10vh;
  }

  @media (min-aspect-ratio: 7/5) {
    padding: 8vh 7vw 7vh;
    img {
      width: auto !important;
      bottom: 0 !important;
    }
  }

  @media (min-width: 1600px) {
    padding: 10vh 10vw 7vh;
  }
`

const Logo = () => {
  const query = useStaticQuery(graphql`
    {
      file(name: { regex: "/logo1/i" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <LogoContainer
      initial={{ opacity: 0, y: 70, x: -70, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, x: 0, scale: 1 }}
      transition={{ duration: 1.5 }}
    >
      <Image fluid={query.file.childImageSharp.fluid}></Image>
    </LogoContainer>
  )
}

export default Logo
