import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const StyledContactField = styled.li`
  display: flex;
  align-items: center;
  margin: 0 0 var(--margin-y);

  div {
    flex-basis: 40px;
    flex-shrink: 0;
    padding: 4px;
    margin-right: 5px;
    color: var(--primary-color);
  }

  p {
    font-size: var(--font-size);
    flex-grow: 1;
  }
`

const ContactField = ({ children, icon }) => (
  <StyledContactField>
    <div>
      <FontAwesomeIcon icon={icon} />
    </div>
    <p>{children}</p>
  </StyledContactField>
)

export default ContactField
