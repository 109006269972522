import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from "../assets/images/favicon.ico"

const SEO = () => {
  const query = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          keywords
          author
          image
          url
          author
        }
      }
    }
  `)

  const seo = query.site.siteMetadata

  return (
    <Helmet>
      <html lang="pl-PL"></html>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="image" content={seo.image} />
      <meta name="author" content={seo.author} />
      <link rel="icon" href={seo.image} />
      <link rel="shortcut icon" href={favicon} type="image/x-icon"></link>

      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={`${seo.url}/static/logo1.png`} />
      <meta property="og:image:alt" content={seo.title} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={seo.title} />
    </Helmet>
  )
}

export default SEO
