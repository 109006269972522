import React from "react"
import StyledOffer from "./StyledOffer"

const OfferVideo = ({ video, description }) => (
  <StyledOffer>
    <video controls muted loop>
      <source src={video} type="video/mp4" />
    </video>
    <h3>{description}</h3>
  </StyledOffer>
)

export default OfferVideo
