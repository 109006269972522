// import React from "react"
import styled from "styled-components"

const Heading = styled.h2`
  flex-basis: 100%;
  margin: calc(var(--margin-y) * 1.7) var(--margin-x) var(--margin-y);
  font-size: var(--heading-font-size);
  text-align: center;
  text-shadow: ${({ shadow }) => (shadow ? `3px 5px 6px #888` : "none")};
`

export default Heading
