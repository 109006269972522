import React, { useState } from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { motion } from "framer-motion"

const StyledSlides = styled.div`
  --slide-width: ${({ currentSlides }) => {
    const numSlides = currentSlides.length
    if (numSlides === 1) return "55vw"
    if (numSlides === 2) return `${100 / 3 + 5}vw` //TODO: Warunek dla dwóch aktywnych slidów, usunąć, jeśli okaże się niepotrzebny.
    if (numSlides === 3) return `${100 / 5 + 5}vw`
  }};

  display: flex;
  width: calc(var(--slide-width) * ${({ numberOfSlides }) => numberOfSlides});
  margin-left: calc((100vw - var(--slide-width)) / 2);

  transform: translateX(
    ${({ currentSlides, numberOfSlides }) => {
      const percentTranslate = -1 * (100 / numberOfSlides) * currentSlides[0]

      if (currentSlides.length === 1) return `${percentTranslate}%`

      return `calc(${percentTranslate}% - ${100 / 5 + 5}vw)`
    }}
  ) !important;

  cursor: move;
  transition: 0.3s;
`
const Slide = styled.div`
  width: var(--slide-width);
  padding: 15px;
  transform: scale(${({ active }) => (active ? 1.15 : 1)});
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  transition: 0.3s;

  @media (min-width: 500px) {
    padding: 25px;
  }
  @media (min-width: 650px) {
    padding: 35px;
  }

  @media (min-width: 800px) {
    padding: 50px;
  }

  @media (orientation: landscape) and (min-width: 700px) {
    padding: 15px 25px;
  }

  @media (orientation: landscape) and (min-width: 900px) {
    padding: 2vw 4vw;
  }
  img {
    user-select: none;
  }
`

const Slides = ({ data, currentSlidesId, numberOfSlides, changeSlide }) => {
  const [eventStart, setEventStart] = useState(null)

  const checkIsSlideChange = eventEnd => {
    if (eventStart[0] - eventEnd > 70) changeSlide("next")
    if (eventStart[0] - eventEnd < -70) changeSlide("prev")
  }

  const scrollFix = e => {
    e.preventDefault()
    if (e.pointerType === "touch") {
      const scrollChangeX = eventStart[0] - e.clientX
      const scrollChangeY = eventStart[1] - e.clientY
      if (Math.abs(scrollChangeX) < Math.abs(scrollChangeY)) {
        window.scroll(0, eventStart[2] + scrollChangeY)
      }
    }
  }

  return (
    <motion.div
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      onDragStart={(e, i) => {
        document.documentElement.style.setProperty("scroll-behavior", "auto")
        setEventStart([e.clientX, e.clientY, window.pageYOffset])
      }}
      onDragEnd={(e, i) => {
        document.documentElement.style.setProperty("scroll-behavior", "smooth")
        checkIsSlideChange(e.clientX)
      }}
      onDrag={(e, i) => scrollFix(e)}
      dragMomentum={true}
      dragElastic={0.2}
    >
      <StyledSlides
        numberOfSlides={numberOfSlides}
        currentSlides={currentSlidesId}
      >
        {data.map(({ node }, i) => (
          <Slide key={i} active={currentSlidesId.includes(i)}>
            <Image
              className="loading"
              fluid={node.fluid}
              alt={`${i + 1} zdjęcie realizacji.`}
              title={`${i + 1} zdjęcie realizacji.`}
            />
          </Slide>
        ))}
      </StyledSlides>
    </motion.div>
  )
}

export default Slides
