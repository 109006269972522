import React from "react"
import Header from "../Layout/Header"
import About from "../Layout/About"
import Offer from "../Layout/Offer"
import Assessment from "../Layout/Assesment"
import Realisation from "../Layout/Realisation"
import Collaborate from "../Layout/Collaborate"
import Constructions from "../Layout/Constructions"
import Contact from "../Layout/Contact"
import SEO from "../components/SEO"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faCheck,
  faAngleLeft,
  faAngleRight,
  faPhone,
  faEnvelope,
  faCaretSquareRight,
  faTruck,
  faGem,
  faPencilRuler,
  faTools,
  faBroom,
  faGraduationCap,
  faDotCircle,
  faUser,
  faHandshake,
  faBuilding,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons"

const IndexPage = ({ children }) => {
  library.add(
    faCheck,
    faAngleLeft,
    faAngleRight,
    faPhone,
    faEnvelope,
    faCaretSquareRight,
    faTruck,
    faGem,
    faPencilRuler,
    faTools,
    faBroom,
    faGraduationCap,
    faDotCircle,
    faUser,
    faHandshake,
    faBuilding,
    faStopwatch
  )
  return (
    <>
      <SEO />
      {children}
      <Header />
      <div className="contentWrapper">
        <Offer />
        <About />
        <Assessment />
        <Constructions />
        <Realisation />
        <Collaborate />
      </div>
      <Contact />
    </>
  )
}

export default IndexPage
