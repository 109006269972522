import React from "react"
import styled from "styled-components"
import Feature from "../components/Feature"
import Heading from "../components/simpleComponents/Heading"

const StyledAbout = styled.section`
  max-width: 830px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  @supports not (-ms-ime-align: auto) {
      justify-content: space-evenly
  }

  @media (min-width: 1365px) {
    max-width: 1370px;
  }
`

const About = () => {
  return (
    <StyledAbout>
      <Heading shadow>Dlaczego my?</Heading>
      <Feature icon="truck">
        Swoje usługi świadczymy na terenie całej Polski.
      </Feature>
      <Feature icon="gem">
        Pracujemy <br /> z wykorzystaniem techniki diamentowej.
      </Feature>
      <Feature icon="graduation-cap">
        Doświadczenie. Jesteśmy na rynku od ponad 11 lat.
      </Feature>
      <Feature icon="tools">
        Posiadamy wysokiej jakości sprzęt marki Hilti, Stihl, Bosch, Makita itd.
      </Feature>
      <Feature icon="dot-circle">
        Zapewniamy równą powierzchnie odwiertów.
      </Feature>
      <Feature icon="pencil-ruler">
        Używamy narzędzi pozwalających na dużą precyzję.
      </Feature>
      <Feature icon="stopwatch">
        Zlecenia wykonywane <br /> z dotrzymaniem terminów.
      </Feature>
      <Feature icon="broom">
        Zostawiamy po sobie porządek na miejscu pracy.
      </Feature>
    </StyledAbout>
  )
}

export default About
