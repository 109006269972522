import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"

const ImgContainer = styled.div`
  display: none;
  @media (min-aspect-ratio: 7/5) {
    display: block;
    position: absolute;
    left: 5%;
    right: 55%;
    bottom: 0;
    background-color: #ddd;
    box-shadow: 0 0 50px 15px rgba(0, 0, 0, 0.7);
    img {
      top: auto !important;
      bottom: 0 !important;
    }
  }
`

const HeaderImage = () => {
  const query = useStaticQuery(graphql`
    {
      file(name: { eq: "header-image" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <ImgContainer>
      <Image
        className="loading"
        fluid={query.file.childImageSharp.fluid}
        alt="Logo firmy AS Ciecie, Wiercenie w betonie"
        title="Logo"
      />
    </ImgContainer>
  )
}

export default HeaderImage
