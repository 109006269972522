import { motion } from "framer-motion"
import styled from "styled-components"

const ProgresBar = styled(motion.div)`
  position: relative;
  margin: var(--margin-y) auto;
  width: 200px;
  height: 13px;
  background-color: var(--tertiary-color);
  box-shadow: 0 0 2px 3px var(--primary-color);
  border-radius: 8px;
  overflow: hidden;
  transition: background-color 0.3s;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 13px;
    border-radius: 8px;

    width: ${({ currentSlidesId, numberOfSlides }) => {
      if (currentSlidesId.length === 3) {
        return `${(currentSlidesId[2] / (numberOfSlides - 1)) * 100}%`
      }
      return `${(currentSlidesId[0] / (numberOfSlides - 1)) * 100}%`
    }};

    background-color: var(--primary-color);
    transition: 0.3s;
  }

  &:hover {
    background-color: var(--transparent-color);
  }
`

export default ProgresBar
