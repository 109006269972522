import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion } from "framer-motion"

const StyledButton = styled(motion.button)`
  position: absolute;
  top: calc(50% - 25px);
  left: ${({ prev }) => (prev ? "15px" : "auto")};
  right: ${({ prev }) => (prev ? "auto" : "15px")};
  width: 50px;
  height: 50px;
  background-color: transparent;
  outline: none !important;
  border: none;
  cursor: pointer;
  color: ${({ active }) => (active ? "var(--primary-color)" : "#777")};
`
const SliderButton = ({ active, click, prev }) => (
  <StyledButton
    onClick={click}
    whileTap={{ scale: 0.9 }}
    whileHover={{ scale: 1.2 }}
    prev={prev}
    active={active}
  >
    {prev ? (
      <FontAwesomeIcon icon="angle-left" />
    ) : (
      <FontAwesomeIcon icon="angle-right" />
    )}
  </StyledButton>
)

export default SliderButton
