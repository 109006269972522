import { createGlobalStyle } from "styled-components"
import spinner from "../assets/images/spinner.gif"

const GlobalStyle = createGlobalStyle`
  :root {
    --bgc-color: #DFDFDF;
    --primary-color: #2e25b1;
    --secondary-color: #DFDFDF;
    --tertiary-color: #eee;
    --transparent-color: rgba(46, 37, 177, 0.1);
    --font-color: #DFDFDF;
    --font-size: 16px;
    --heading-font-size: 28px;
    --margin-x: 15px;
    --margin-y: 20px;
    --offerWidth: 100%;
    --max-section-width: 1600px;

    scroll-behavior: smooth;
  }

  *,*::after,*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: "montserrat", sans-serif;
    background: linear-gradient(36deg, rgba(6,0,43,1) 25%, rgba(0,16,121,1) 78%, rgba(0,7,177,1) 100%);
    background: linear-gradient(18deg, rgba(3,0,19,1) 25%, rgba(0,13,98,1) 78%, rgba(0,7,184,1) 100%);
    background-attachment: fixed;
  }

  ul {
    list-style: none;
  }

  path,
svg {
  width: 100% !important;
  height: 100% !important;
}

.contentWrapper {
  position:relative;
  padding-bottom: var(--margin-y);
  z-index:1;
  background-color: var(--bgc-color);
}

.loading {
  background: #888 url(${() => spinner}) center no-repeat;
}

@media (min-width: 360px) {
  --margin-x: 20px;
}

@media (min-width: 400px) {
  :root {
    --heading-font-size: 32px;
    --margin-x: 25px;
    --margin-y: 25px;
  }
}

@media (min-width: 450px) {
  :root {
  --margin-x: 35px;
  }
}

@media (min-width: 450px) {
  :root {
  --offerWidth: 400px;
  }
}

@media (min-width: 590px) {
  :root {
    --offerWidth: 40%;
  }
}

@media (min-width: 1000px) {
  :root {
    --offerWidth: 26%;
  }
}

@media (min-width: 1400px) {
  :root {
  --font-size: 18px;
  }
}
`

export default GlobalStyle
