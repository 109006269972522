import React from "react"
import Heading from "../components/simpleComponents/Heading"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import OfferImage from "../components/offerFields/OfferImage"
import OfferField from "../components/offerFields/OfferField"
import OfferVideo from "../components/offerFields/OfferVideo"
import video1 from "../assets/video/wiercenie_otworów_800x887.mp4"
import video2 from "../assets/video/osuszanie_800x887.mp4"

const StyledOffer = styled.main`
  display: flex;
  max-width: var(--max-section-width);
  margin: 0 auto;
  padding: 0 var(--margin-x);
  flex-wrap: wrap;
  justify-content: space-between;
  
  @supports not (-ms-ime-align: auto) {
      justify-content: space-evenly
  }
`

const Offer = () => {
  const images = useStaticQuery(graphql`
    {
      allImageSharp {
        edges {
          node {
            fluid {
              originalName
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `)

  const filterQuery = fragmentOfName => {
    const [
      filteredImage,
    ] = images.allImageSharp.edges.filter(({ node: { fluid } }) =>
      fluid.originalName.includes(fragmentOfName)
    )

    return filteredImage.node.fluid
  }

  return (
    <StyledOffer>
      <Heading shadow id="offer">
        Oferta
      </Heading>
      <OfferVideo
        video={video1}
        description="Wiercenie otworów o średnicy od 12 do 1000 mm."
      />
      <OfferVideo
        video={video2}
        description="Osuszanie budynków metodą cięcia poprzez wykonanie izolacji poziomej."
      />
      <OfferImage
        description="Wiercenie otworów pod kątem."
        fluid={filterQuery("wiercenie")}
      />
      <OfferImage
        description="Wycinanie ścian, stropów, schodów."
        fluid={filterQuery("wycinanie")}
      />
      <OfferImage
        description="Wycinanie otworów drzwiowych, okiennych."
        fluid={filterQuery("drzwiowy")}
      />
      <OfferImage
        description="Wzmacnianie konstrukcji żelbetowych taśmą Sika Carbodur."
        fluid={filterQuery("tasma")}
      />
      <OfferField description="Kucie." />
      <OfferField description="Szlifowanie." />
      <OfferField description="Frezowanie." />
      <OfferField description="Wklejanie prętów." />
    </StyledOffer>
  )
}

export default Offer
