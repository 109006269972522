import React, { useEffect, useState } from "react"
import ContactField from "../components/ContactField"
import Heading from "../components/simpleComponents/Heading"
import styled from "styled-components"
import Footer from "../Layout/Footer"

const StyledContactSection = styled.section`
  height: calc(300px + 3 * var(--margin-y));
  padding: 0 var(--margin-x);
  color: var(--font-color);

  .wrapper {
    visibility: ${({ scroll }) => (scroll > 1000 ? "initial" : "hidden")};
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const ContactList = styled.ul`
  padding: 0 var(--margin-x) var(--margin-y);
  margin: 0 auto;
  max-width: 350px;
`

const Contact = () => {
  const [scrollPos, setScrollPos] = useState(0)

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setScrollPos(window.scrollY)
    })
  }, [])

  return (
    <StyledContactSection
      id="contact"
      scroll={scrollPos}
      className="contactContainer"
    >
      <div className="wrapper">
        <Heading>Dane kontaktowe</Heading>
        <ContactList>
          <ContactField icon="user">Andrzej Zawadzki</ContactField>
          <ContactField icon="phone">Tel: 512-746-858</ContactField>
          <ContactField icon="envelope">
            Email: as.cieciewiercenie@interia.pl
          </ContactField>
          <ContactField icon="caret-square-right">
            Nip: 762-184-62-57
          </ContactField>
        </ContactList>
        <Footer>
          &copy; <h1>AS Cięcie, wiercenie w betonie</h1>. 2020
        </Footer>
      </div>
    </StyledContactSection>
  )
}

export default Contact
