import styled from "styled-components"

const StyledOffer = styled.article`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex-basis: var(--offerWidth);
  margin: 0 0 var(--margin-y);
  padding: 20px;
  background: var(--tertiary-color);
  box-shadow: 0 0 10px -2px #888;

  div {
    width: 100%;
    border-radius: 5px;
  }

  video {
    width: 100%;
    border-radius: 5px;
  }

  h3 {
    margin: auto 0;
    padding: 10px 5px 0;
    font-size: var(--font-size);
    text-align: center;
    font-weight: 300;
  }
`

export default StyledOffer
