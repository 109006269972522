import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Menu from "../components/Header/Menu"
import Logo from "../components/Header/Logo"
import HeaderImage from "../components/Header/HeaderImage"

const StyledHeader = styled.header`
  height: 100vh;

  .headerWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media (min-aspect-ratio: 7/5) {
    .logoAndNavWrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
`

const Header = () => {
  const [scrollPos, setScrollPos] = useState(0)
  const [windowHeight, setWindoWHeight] = useState(1)
  useEffect(() => {
    let windowH = window.outerHeight
    setWindoWHeight(windowH)
    document.addEventListener("scroll", () => {
      const scrollPos = window.scrollY
      setScrollPos(scrollPos)
    })
    window.addEventListener("resize", () => {
      windowH = window.outerHeight
      setWindoWHeight(windowH)
    })
  }, [])

  return (
    <StyledHeader>
      <div
        style={{
          transform: `translateY(-${scrollPos / 3}px`,
          opacity: 1.1 - scrollPos / windowHeight,
        }}
        className="headerWrapper"
      >
        <HeaderImage />
        <div className="logoAndNavWrapper">
          <Logo />
          <Menu />
        </div>
      </div>
    </StyledHeader>
  )
}

export default Header
