import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const StyledOfferField = styled.article`
  flex-basis: 100%;
  margin: var(--margin-y) 0 0;
  border-radius: 20px 0 0 20px;
  border-bottom: 2px solid var(--primary-color);
  box-shadow: -4px 4px 6px -2px #888;

  .icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    padding: 10px;
    background-color: var(--primary-color);
    color: var(--font-color);
    border-radius: 50% 0 0 50%;
  }

  h3 {
    display: inline-block;
    width: calc(100% - 80px);
    font-size: var(--font-size);
    text-align: center;
    font-weight: 300;
  }

  @media (min-width: 690px) {
    flex-basis: 40%;

    h3 {
      width: calc(100% - 40px);
    }
  }
`

const OfferField = ({ description }) => (
  <StyledOfferField>
    <div className="icon">
      <FontAwesomeIcon icon="check" />
    </div>
    <h3>{description}</h3>
  </StyledOfferField>
)

export default OfferField
