import styled from "styled-components"

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  text-align: center;
  font-size: 12px;
  line-height: 25px;
  color: white;

  h1 {
    display: inline;
    font-size: 12px;
    font-weight: 300;
  }
`

export default Footer
